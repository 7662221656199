<template>
  <BasicModal
    :title="title"
    width="500px"
    :visible="visible"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <BasicForm
      ref="formRef"
      :labelCol="{ span: 5 }"
      :formItemsMap="formItemsMap"
      v-model:formData="formData"
    ></BasicForm>
  </BasicModal>
</template>

<script setup>
import {
  defineProps,
  computed,
  ref,
  defineEmits,
  reactive,
  onMounted,
} from "vue";
import { apiDictListByCode } from "@/api/common";
import { message } from "ant-design-vue";
import { apiSpace } from "@/api/IoT/project";

const props = defineProps({
  projectId: {
    type: String,
    default: null,
  },
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef = ref(null);

const title = computed(() => `${props.spaceId ? "修改" : "添加"}空间`);

onMounted(() => {
  initFormItems();
  initFormData();
});

const formItemsMap = reactive({
  spaceName: {
    label: "空间名称",
    prop: "spaceName",
    type: "input",
    required: true,
    requiredMessage: "请输入",
  },
  spaceType: {
    label: "空间分类",
    prop: "spaceType",
    type: "select",
    options: [],
    required: true,
    requiredMessage: "请选择",
  },
  parentId: {
    label: "⽗级空间",
    prop: "parentId",
    type: "treeSelect",
    disabled: false,
    showSearch: true,
    treeNodeFilterProp: "spaceName",
    options: [],
    fieldNames: {
      label: "spaceName",
      value: "spaceId",
    },
    required: true,
    requiredMessage: "请选择",
  },
  spaceProportion: {
    label: "建筑面积",
    prop: "spaceProportion",
    type: "input",
    suffix: "㎡",
    inputType: "number",
  },
  spaceDescription: {
    label: "备注",
    prop: "spaceDescription",
    type: "textarea",
  },
});
const initFormItems = async () => {
  if (props.detail) {
    formItemsMap.parentId.disabled = true;
  }
  apiSpace.list(props.projectId).then((res) => {
    formItemsMap.parentId.options = res.result;
  });
  apiDictListByCode("CNF_SUBAREA_TYPE").then((res) => {
    formItemsMap.spaceType.options = res.result;
  });
};
const formData = reactive({
  spaceName: "",
  spaceType: undefined,
  parentId: undefined,
  spaceProportion: "",
  spaceDescription: "",
});
const initFormData = async () => {
  if (!props.detail) return;
  try {
    for (const key in formData) {
      formData[key] = props.detail[key];
    }
  } catch (error) {
    console.log(error);
  }
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef.value.formRef.validateFields();
    const { detail, projectId } = props;
    const params = {
      projectId,
      ...formData,
    };
    if (detail) {
      params.spaceId = detail.spaceId;
      delete params.parentId;
    }
    confirmLoading.value = true;
    await apiSpace[detail ? "edit" : "add"](params);
    message.success(`${detail ? "修改" : "添加"}成功`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped></style>
